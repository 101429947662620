import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_auth_2: "Email already exists",
  nam_auth_5: "Wrong Credentials",
  nam_auth_4: "Forbidden for your role",
  nam_auth_1: "Parameters Not Valid",
  nam_auth_0: "Generic Error",
  nam_controller_0: "Server Error",
  default: "Generic Error",
};

const SchoolService = {
  getAll: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/schools/gets",
      });

      const sortedSchools = [...resp.schools].sort((a, b) =>
        a.description.localeCompare(b.description),
      );
      return sortedSchools;
    } catch (error) {
      throw new Error(
        "Fetching schools failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
  create: async (schoolData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/schools/create",
        data: schoolData,
      });

      return resp.school;
    } catch (error) {
      throw new Error(
        "Creating school failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
  delete: async (schoolId) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/schools/delete",
        data: {
          request: schoolId,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Delete school failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  update: async (schoolData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/schools/update",
        data: { id: schoolData.id, description: schoolData.description },
      });
      console.log(resp.school);
      return resp.school;
    } catch (error) {
      throw new Error(
        "Update failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default SchoolService;
