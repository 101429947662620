import { useQuery } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";

export function useImage(imageId) {
  const {
    data: image,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["images", imageId],
    queryFn: async () => {
      if (!imageId) return "";
      const image = await ImagesService.getJpg(imageId);
      return image ? URL.createObjectURL(image) : "";
    },
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 30,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: (err) => console.error("Errore useQuery:", err),
  });

  return { image: image || "", isLoading, error };
}
