// src/components/lists/GenericListItem.js
import React, { useState } from "react";
import ItemForm from "../Form/ItemForm";

const ListItem = ({ item, onEdit, onDelete, fields }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = (data) => {
    onEdit({ ...item, ...data });
    setIsEditing(false);
  };

  return (
    <div className="flex flex-wrap items-center border-b border-gray-200 py-2">
      {isEditing ? (
        <ItemForm
          defaultValues={item}
          onSubmit={handleSave}
          onCancel={handleCancel}
          fields={fields}
        />
      ) : (
        <>
          <div className="flex flex-1 items-center py-4">
            {fields.map((field) => (
              <div key={field.name} className="h-fit flex-1 px-4">
                {item[field.name]}
              </div>
            ))}
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handleEditClick}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            >
              Edit
            </button>
            <button
              onClick={() => onDelete(item.id)}
              className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ListItem;
