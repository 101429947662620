import { useQuery, useQueryClient } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";
import WorkService from "../service/WorkService";

const useSecondaryImages = (idWork) => {
  const queryClient = useQueryClient();

  const fetchImages = async () => {
    const work = await queryClient.fetchQuery(["work", idWork], async () => {
      const response = await WorkService.get(idWork);
      return response.data;
    });

    const imagePromises = work?.photoIds
      ?.filter((image) => !image.primary)
      ?.map(async (image) => {
        try {
          const blob = await ImagesService.getJpg(image?.id);
          const url = URL.createObjectURL(blob);
          console.log("useSecondaryImages", image.id, url);
          return { id: image?.id, url };
        } catch (error) {
          console.error(`Failed to fetch image with id ${image?.id}`, error);
          return null;
        }
      });

    const settledPromises = imagePromises
      ? await Promise.allSettled(imagePromises)
      : [];

    const images = settledPromises
      .filter(
        (result) => result.status === "fulfilled" && result.value !== null,
      )
      .map((result) => result.value);
    return images;
  };

  const { data, error, isLoading } = useQuery(
    ["secondaryImages", idWork],
    fetchImages,
    {
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 30,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    images: data,
    isLoading,
    error,
  };
};

export default useSecondaryImages;
