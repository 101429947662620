// src/data/useCreateWork.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import workService from "../service/WorkService"; // Adatta il percorso in base alla tua struttura
import { useNotification } from "../context/NotificationContext";

export function useCreateWork() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: createWork, isLoading: isCreating } = useMutation({
    mutationFn: async (newWork) => {
      try {
        const response = await workService.create(newWork);
        console.log("API Response:", response); // Log della risposta dell'API
        return response; // Assicurati di restituire la risposta
      } catch (error) {
        throw error; // Rilancia l'errore per la gestione
      }
    },
    onSuccess: (newWork) => {
      // Potresti voler fare qualcosa al successo, come invalidare cache o mostrare un messaggio
      console.log("Work added successfully");
      queryClient.cancelQueries(["worksData"]);

      // Aggiorna localStorage con il nuovo work
      const savedData = JSON.parse(localStorage.getItem("worksData")) || [];
      const updatedData = [...savedData, newWork];
      const updatedSortedData = updatedData.sort(
        (a, b) => a.catalogue - b.catalogue,
      );
      localStorage.setItem("worksData", JSON.stringify(updatedSortedData));

      addNotification("Work Created", "success");
      // Invalida e refetch dei dati
      // Aggiorna lo stato dei dati locali immediatamente
      queryClient.setQueryData(["worksData"], updatedData);
      queryClient.refetchQueries(
        { queryKey: ["worksData"] },
        { cancelRefetch: true },
      );
    },

    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error adding work:", error);
    },
  });

  return { isCreating, createWork };
}
