import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Server Error",
  nam_auth_6: "Token Expired",
  nam_image_1: "Image not found",
  default: "Generic Error",
};

const ImagesService = {
  getJpg: async (idPhoto) => {
    try {
      //const token = localStorage.getItem("token");

      const resp = await makeAPIRequest({
        method: "get",
        url: "/images/getJPEG/" + idPhoto,
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      throw new Error(
        "Fetching JPG " +
          idPhoto +
          " failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  getPng: async (idPhoto) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/images/getPNG/" + idPhoto,
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      throw new Error(
        "Fetching PNG " +
          idPhoto +
          "failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  upload: async ({ workId, primary, image }) => {
    try {
      const formData = new FormData();
      formData.append("workId", workId);
      formData.append("primary", primary);
      formData.append("file", image);
      const resp = await makeAPIRequest({
        method: "post",
        url: "/images/upload",
        body: "formData",
        data: formData,
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Upload photo failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  delete: async (imageId) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/images/delete",
        data: {
          request: imageId,
        },
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Delete photo " +
          imageId +
          "failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
  setPrimaryImage: async (imageId) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/images/setMainPhoto",
        data: {
          request: imageId,
        },
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Setting primary photo " +
          imageId +
          "failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default ImagesService;
