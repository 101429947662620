import { useQueryClient, useMutation } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";
import { useNotification } from "../context/NotificationContext";

export function useUploadImage({
  workId,
  primary,
  image,
  onUploadSuccess = () => {},
}) {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const uploadImageMutation = useMutation(
    async () => {
      const newImageId = await ImagesService.upload({ workId, primary, image });
      return newImageId;
    },
    {
      onSuccess: async (newImageId) => {
        await queryClient.cancelQueries(["worksData"]);
        await queryClient.cancelQueries(["work", workId]);
        await queryClient.cancelQueries(["secondaryImages", workId]);
        addNotification("Image uploaded", "success");
        await queryClient.invalidateQueries(["work", workId]);
        await queryClient.invalidateQueries(["worksData"]);
        await queryClient.invalidateQueries(["secondaryImages", workId]);
        onUploadSuccess();
      },
      onError: (error) => {
        // Gestisci l'errore
        addNotification(error.message, "error");
        console.error("Error uploading image:", error);
      },
    },
  );

  return {
    uploadImage: uploadImageMutation.mutateAsync,
    isUploading: uploadImageMutation.isLoading,
  };
}
