import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Server Error",
  nam_auth_6: "Token Expired",
  default: "Generic Error",
};

const WorkService = {
  getAll: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/works/gets",
      });

      const sortedWorks = resp.response.sort(
        (a, b) => a.catalogue - b.catalogue,
      );
      return sortedWorks;
    } catch (error) {
      throw new Error(
        "Fetching All Work failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  get: async (workId) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/works/get/" + workId,
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Fetching Work " +
          workId +
          " failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  update: async (workData, id) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/works/update",
        data: { id: id, ...workData },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Update failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  create: async (workData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/works/create",
        data: workData,
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Creation failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  delete: async (workId) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/works/delete",
        data: {
          request: workId,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Delete failed: " +
          (error.errorCode === "nam_controller_0"
            ? ERROR_MESSAGES[error.errorCode] + ": " + error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default WorkService;

/* function updateWork(updatedWorkData) {
  const index = data.findIndex(
    (artwork) => artwork.id === updatedWorkData.id
  );
  // Crea una copia dei dati correnti
  const newData = [...data];
  // Aggiorna l'opera d'arte nei dati
  newData[index] = updatedWorkData;
  // Aggiorna lo stato dei dati
  //setData(newData);
  localStorage.removeItem(`searchData`);
  localStorage.setItem(`searchData`, JSON.stringify(newData));
} */
