import { useQueryClient, useMutation } from "@tanstack/react-query";
import ArtistService from "../service/ArtistService";
import { useNotification } from "../context/NotificationContext";

export function useEditArtist() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: editArtist, isLoading: isEditing } = useMutation({
    mutationFn: async (artistData) => {
      return await ArtistService.update(artistData); // Ritorna i dati aggiornati
    },

    onSuccess: async (updatedArtist) => {
      //Aggiorna i dati restuiti dal server nel localStorage locale
      const savedData = JSON.parse(localStorage.getItem("artistsData")) || [];
      const updatedData = savedData?.map((artist) =>
        artist.id === updatedArtist.id ? updatedArtist : artist,
      );
      localStorage.setItem("artistsData", JSON.stringify(updatedData));
      addNotification("Artist Updated", "success");
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["artists"]);
      queryClient.refetchQueries(
        { queryKey: ["worksData"] },
        { cancelRefetch: true },
      );
    },
    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error editing artist:", error);
    },
  });

  return { editArtist, isEditing };
}

/* const updateWorkData = await WorkService.update(data, id);
updateWork(updateWorkData);



function updateWork(updatedWorkData) {
    const index = data.findIndex(
      (artwork) => artwork.id === updatedWorkData.id
    );
    // Crea una copia dei dati correnti
    const newData = [...data];
    // Aggiorna l'opera d'arte nei dati
    newData[index] = updatedWorkData;
    // Aggiorna lo stato dei dati
    //setData(newData);
    localStorage.removeItem(`searchData`);
    localStorage.setItem(`searchData`, JSON.stringify(newData));


 */
