import { useQueryClient, useMutation } from "@tanstack/react-query";
import WorkService from "../service/WorkService";
import { useNotification } from "../context/NotificationContext";

export function useEditWorksData() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: editWork, isLoading: isEditing } = useMutation({
    mutationFn: ({ workId, updatedWorkData }) =>
      WorkService.update(updatedWorkData, workId),

    onSuccess: async (newWork) => {
      queryClient.cancelQueries(["worksData"]);
      // Aggiorna localStorage con il nuovo work
      const savedData = JSON.parse(localStorage.getItem("worksData")) || [];
      const updatedData = savedData?.map((work) =>
        work.id === newWork.id ? newWork : work,
      );
      localStorage.setItem("worksData", JSON.stringify(updatedData));
      addNotification("Work updated", "success");
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["work", newWork.id]);
      queryClient.refetchQueries(
        { queryKey: ["worksData"] },
        { cancelRefetch: true },
      ); // Aggiorna lo stato dei dati locali immediatamente
      await queryClient.setQueryData(["worksData"], updatedData);
    },
    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error editing work:", error);
    },
  });

  return { isEditing, editWork };
}

/* const updateWorkData = await WorkService.update(data, id);
updateWork(updateWorkData);



function updateWork(updatedWorkData) {
    const index = data.findIndex(
      (artwork) => artwork.id === updatedWorkData.id
    );
    // Crea una copia dei dati correnti
    const newData = [...data];
    // Aggiorna l'opera d'arte nei dati
    newData[index] = updatedWorkData;
    // Aggiorna lo stato dei dati
    //setData(newData);
    localStorage.removeItem(`searchData`);
    localStorage.setItem(`searchData`, JSON.stringify(newData));


 */
